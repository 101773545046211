<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Affiliate Sales" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
      </div>
      <md-table
        v-model="sales.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start">
            <md-button
              class="md-primary button-icon"
              :disabled="exporting"
              @click="exportExcel"
            >
              Download Excel
              <img src="@/assets/svg/excel.svg" />
            </md-button>
          </div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                md-menu-trigger
                class="md-primary button-icon"
                disabled
              >
                Filter
                <img src="@/assets/svg/filter.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item>Approved</md-menu-item>
                <md-menu-item>Pending</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="S/N" md-numeric>{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Product title">
            <div>
              <div v-for="pItem in item.items" class="pitem" :key="pItem.id">
                {{ pItem.title }}
              </div>
            </div>
          </md-table-cell>

          <md-table-cell md-label="Total Price">
            {{ item.total | formatMoney }}
          </md-table-cell>

          <md-table-cell md-label="Commission">
            {{ (item.total * 0.05 || 0) | formatMoney }}
          </md-table-cell>

          <md-table-cell md-label="Date Created">{{
            item.created_at | formatDate
          }}</md-table-cell>
          <md-table-cell md-label="Actions">
            <md-menu md-size="medium" md-align-trigger>
              <md-button :disabled="updating" md-menu-trigger class="bggreen"
                >Menu</md-button
              >

              <md-menu-content>
                <md-menu-item @click="setSales(item)">Preview</md-menu-item>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="sales.mdPage"
        :records="sales.mdCount"
        :per-page="sales.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>

    <md-dialog :md-active.sync="show">
      <md-dialog-title>Affiliate Sales Details</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="sale">
        <div class="item">
          <strong>Sub Total</strong>
          <span> {{ sale.subtotal | formatMoney }}</span>
        </div>
        <div class="item">
          <strong>Total</strong>
          <span> {{ sale.total | formatMoney }}</span>
        </div>
        <div class="item">
          <strong>Payment Status</strong>
          <div
            class="badge"
            :class="{ success: sale.payment_status == 'paid' }"
          >
            {{ sale.payment_status }}
          </div>
        </div>
        <div class="item">
          <strong>sale Code</strong>
          <span> {{ sale.order_code }}</span>
        </div>

        <div class="item">
          <strong>Date Created</strong>
          <span> {{ sale.created_at | formatDate }}</span>
        </div>
        <hr />
        <div>
          <h4>Items</h4>
        </div>

        <div v-if="sale.items.length">
          <div v-for="item in sale.items" :key="item.id">
            <div class="d-flex" style="justify-content: space-between">
              <div>{{ item.title }}</div>
              <div>{{ item.quantity }} X {{ item.price }}</div>
            </div>
          </div>
        </div>
        <div v-else>Empty Items</div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("affiliate");
export default {
  name: "affiliate",
  data() {
    return {
      updating: false,
      deleting: false,
      exporting: false,
      search: "",
      show: false,
      time: null,
      sale: null,
      showS: false,
      multi_services: [],
      checkAllServices: false,
      approval_status: "",
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
    handyman(val) {
      let data = [];
      this.multi_services = [];
      this.checkAllServices = false;

      val.services.map((service) => {
        if (service.pivot.approval_status == "approved") {
          data.push(service.pivot.id);
        }
      });

      this.multi_services = data;
    },
  },

  methods: {
    ...mapActions(["getAffiliatesSales"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getAffiliatesSales(data);
    },
    async changeAffiliateStatus(id, status) {
      try {
        this.updating = true;
        await this.updateAffiliateStatus({ approval_status: status, id });
        this.updating = false;
        this.toast("success", "Affiliate Status Updated ");
      } catch (err) {
        this.updating = false;
        console.error(err);
      }
    },
    async exportExcel() {
      this.exporting = true;
      await this.$store.dispatch("auth/export", { model: "affiliate" });
      this.exporting = false;
    },
    async submitSearch() {
      await this.getAffiliate({ keyword: this.search });
    },
    async submitServices() {
      this.updating = true;
      var data = new FormData();
      if (this.approval_status == "") return;
      data.append("approval_status", this.approval_status);
      data.append("services", this.multi_services);
      data.append("_method", "PATCH");

      await this.updateServiceStatus(data);
      this.updating = false;
      this.showS = false;
      this.toast("success", "Status updated");
    },

    setSales(sale) {
      this.sale = sale;
      this.show = true;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    getSales() {
      this.getAffiliatesSales({ id: this.id ? this.id : null });
    },
  },
  created() {},
  mounted() {
    this.getSales();
  },
  computed: {
    ...mapGetters(["sales", "loading"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.badge {
  background: #f3f3f3;

  &.success {
    background: green;
  }

  &.danger {
    background: red;
  }
}

.s-con {
  .head,
  .body .s-items {
    display: flex;
    align-items: center;

    > div {
      width: calc(100% / 3);
    }
  }
  .head {
    font-weight: 700;
  }

  .body .s-items .s-item {
    padding: 10px 0px;
  }
}
.pitem {
  padding: 5px 8px;
  background: #fffafa;
  border-radius: 10px;
}
</style>
